import React from 'react'
import { IStoreChainImpactTileData } from '../@types/IStoreChainImpact'
import { StoreChainImpactResult } from '../StoreChainImpactResult'
import { useChainImpactTexts } from './hooks/useChainImpactTexts'

export const StoreChainImpact: React.FC<{ data: IStoreChainImpactTileData, tileId: string }> = ({
  data, tileId,
}) => {
  const { chainImpact } = useChainImpactTexts()

  return (
    <StoreChainImpactResult
      data={data}
      texts={chainImpact}
      tileId={tileId}
    />
  )
}
