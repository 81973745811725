import useCheckIfPrinting from 'apps/placeme/src/customHooks/useCheckIfPrinting'
import React, { ReactNode } from 'react'

interface WrapIfNotPrintingProps {
  wrapper: ReactNode
  children: ReactNode
}
export const WrapIfNotPrinting = ({
  wrapper, children,
}: WrapIfNotPrintingProps): JSX.Element => {
  const { isPrinting } = useCheckIfPrinting()

  if (isPrinting) return <>{children}</>

  return <>{React.cloneElement(wrapper as React.ReactElement, {}, children)}</>
}
