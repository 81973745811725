import { captureException } from '@sentry/react'
import { AxiosError } from 'axios'

interface ApiResponseData {
  message?: string
  data?: Record<string, unknown>
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sentryErrorHandler = (error: AxiosError<ApiResponseData>) => {
  const { response } = error

  if (!response) {
    captureException(new Error(`Network error: ${error.message}`))
  } else {
    const {
      status,
      data,
      config,
    } = response

    switch (status) {
      case 500:
        captureException(new Error(`Server error: ${data.message ?? 'Unknown server error'}`))
        break
      case 404:
        captureException(new Error(`Resource not found: ${config.url}`))
        break
      case 403:
        captureException(new Error(`Forbidden: ${data.message ?? 'Access denied'}`))
        break
      default:
        captureException(error)
        break
    }
  }
}
