import { BasicLayers, FeatureCollectionLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { ISectionTile } from 'apps/placeme/src/features/Analyse/slice/@types/ISectionTile'
import React, { useMemo } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { ReactComponent as StatusPin } from 'libs/shared/assets/src/lib/markers/kanban/statusPin.svg'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { IStoreChainImpact } from '../../@types/IStoreChainImpact'

interface GetLayersProps {
  tileId: string
  data?: IStoreChainImpact
  sectionTilesData: ISectionTile[]
  features?: IFeatureCollection['data']['features']
  translatedColumnsNames: { [key: string]: string }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetLayers = ({
  tileId, data, sectionTilesData, translatedColumnsNames,
}: GetLayersProps) => {
  const mapLayers = useMemo(() => {
    if (!data) return []
    const features = data?.locations.map(location => {
      const fields = [
        {
          label: translatedColumnsNames.siteId,
          value: location.siteId,
        },
        {
          label: translatedColumnsNames.name,
          value: location.name,
        },
        {
          label: translatedColumnsNames.impact,
          value: `${(location.transactions.percentageChange * 100).toFixed(2)}%`,
        },
      ]

      return {
        geometry: {
          coordinates: [location?.lng, location?.lat],
          type: 'Point',
        },
        properties: {
          title:`<div style="display: flex; flex-direction: column;">
          ${fields.map(field => `<span><b>${field.label}</b>: ${field.value}</span>`).join('')}
        </div>`,
          pinnedItem: {
            iconAnchor:[11, 11],
            class: 'poi-img',
            html: renderToStaticMarkup(<StatusPin fill={defaultTheme.palette.yellow} />),
          },
        },
        type: 'Feature',
      } })
    const layers: (BasicLayers | GeojsonLayers | WMSLayers | FeatureCollectionLayers)[] = [
      {
        id: 'impact-layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'impact',
          },
        },
      }]

    return layers
  }, [tileId, data, sectionTilesData])

  return mapLayers
}
