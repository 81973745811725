import React from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next'
import { Button, NoPrint } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as FilterIcon } from 'libs/shared/assets/src/lib/icons/filter.svg'
import { ReactComponent as CloseIcon } from 'libs/shared/assets/src/lib/icons/closeIconBlue.svg'
import { ITableFilterProps } from '../../@types'
import { UseTableFilterReturnValues } from './hooks'

const Container = styled.div(({ theme }) => {
  const {
    corners, palette,
  } = theme
  return css`
    width: min-content;
    min-width: 396px;
    padding: 20px;
    background: ${palette.light.white};
    border: 2px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius} ${corners.default.borderRadius} 0 0;
    box-sizing: border-box;

    > div:last-child {
      padding-bottom: 0;
    }
  `
})

const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  > svg {
    cursor: pointer;
  }
`

const MainHeader = styled.span(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};
  `
})

const ControlsWrapper = styled(Container)`
  background: ${props => props.theme.palette.light.main};
  border-radius: 0 0 ${props => props.theme.corners.default.borderRadius} ${props => props.theme.corners.default.borderRadius};
  border-top: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
`

const FilterCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 2;
`

const FilterCount = styled.span(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
  color: ${palette.black};
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
  `
})

const CancelButton = styled(Button)`
  color: ${({ theme: { palette } }) => palette.blue};
  background: transparent;
  margin-right: 10px;
  border: none;

  :hover {
    background: transparent;
  }
  :focus {
    background: transparent;
  }
  :active {
    border: none;
  }
`

export const TextButton = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    cursor: pointer;
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  `
})

const FilterPopupButton = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    padding: 5px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > span {
      margin-left: 8px;
      font-size: ${typography.small.pt_13_medium.fontSize};
    }
  `
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ActiveFilter = styled.div(({
  theme: {
    palette, typography,
  },
}) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  color: ${palette.light.white};
  background: ${palette.blue};
  font-size: ${typography.small.pt_13_semibold.fontSize};
  width: min-content;
  padding: .2rem .6rem;
  margin-left: 1rem;

  > span:first-of-type {
    text-transform: capitalize;
    white-space: nowrap;
  }

  > span:last-of-type {
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    margin-left: .5rem;
    white-space: nowrap;
  }

  > svg {
    cursor: pointer;
    margin-left: .4rem;
    padding: .1rem;
    path {
      stroke: ${palette.light.white};
    }
  }
`)

const NoInputAutoFocus = styled.input`
  display: none;
`

/**  To use this component, call useTableFilter hook and pass it's return values to this component. */
export const TableFilter = ({
  headers,
  popupRef,
  allFieldsValid,
  activeFilters,
  filter,
  filters,
  restorePrevState,
  handleCancelClick,
  clearAllFilters,
  applyFiltersAndClose,
  getActiveFilterValue,
  deleteActiveFilter,
}: ITableFilterProps & Omit<UseTableFilterReturnValues, 'tempFilter' | 'setTempFilter' | 'filterData'>): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper className='avoid-page-break-after'>
      <NoPrint>
        <Popup
          ref={popupRef}
          closeOnDocumentClick={false}
          onOpen={restorePrevState}
          position='bottom left'
          trigger={(
            <FilterPopupButton>
              <FilterIcon />
              <span>{t('generic.filter')}</span>
            </FilterPopupButton>
          )}
        >
          <Container>
            <HeaderWrapper>
              <MainHeader>{t('generic.filters')}</MainHeader>
              <CloseIcon onClick={handleCancelClick} />
            </HeaderWrapper>
            <NoInputAutoFocus />
            {filters}
          </Container>
          <ControlsWrapper>
            <FilterCountWrapper>
              <FilterCount>{`${t('account.table.filter.selected_filters')}: ${activeFilters}`}</FilterCount>
              <TextButton onClick={clearAllFilters}>{t('account.table.filter.clear_all')}</TextButton>
            </FilterCountWrapper>
            <CancelButton onClick={handleCancelClick}>{t('generic.cancel')}</CancelButton>
            <Button
              disabled={!allFieldsValid}
              onClick={applyFiltersAndClose}
            >
              {t('generic.apply')}
            </Button>
          </ControlsWrapper>
        </Popup>
      </NoPrint>
      {Object.entries(filter).map(([key, filter]) => (
        <ActiveFilter key={key}>
          <span>
            {t(`generic.${headers.find(header => header.name === key)?.label}` || 'generic.filter')}
            :
          </span>
          <span>{getActiveFilterValue(filter)}</span>
          <NoPrint>
            <CloseIcon
              height='13px'
              onClick={() => deleteActiveFilter(key)}
              width='13px'
            />
          </NoPrint>
        </ActiveFilter>
      ))}
    </Wrapper>
  )
}
