import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { Signal, useSignal } from '@preact/signals-react'
import { RootState } from 'apps/placeme/src/redux/store'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ITableFilter } from '@dataplace.ai/ui-components/organisms/FilterableSortableTable/@types'
import { useUserSettingsContext } from './useUserSettingsContext'

export interface BuildingsTileSettings {
  additionalDataVisible?: boolean
  expandedCategories?: string[]
}
export interface InvestmentsNewTileSettings {
  selectedCategories?: ResourceWithId[]
  comparedSelectedCategories?: ResourceWithId[]
  otherInvestmentsTableFilter?: ITableFilter
  comparedOtherInvestmentsTableFilter?: ITableFilter
}
export interface POIsTileSettings {
  poisTableFilter?: ITableFilter
  comparedPoisTableFilter?: ITableFilter
}
export interface ReportSettings {
  buildings?: {
    [key: string]: BuildingsTileSettings
  }
  investmentsNew?: {
    [key: string]: InvestmentsNewTileSettings
  }
  pois?: {
    [key: string]: POIsTileSettings
  }
}
export type RequiredNonUndefined<T> = {
  [P in keyof T]-?: Exclude<T[P], undefined>
}
export type KeyOfSetting<TileType extends keyof NonUndefinedReportSettings> =
  TileSettingsValue<TileType> extends Record<string, infer V>
    ? V extends Record<string, unknown>
      ? keyof V
      : never
    : never
export type NonUndefinedReportSettings = RequiredNonUndefined<ReportSettings>
export type ReportSettingsValue<Key extends keyof NonUndefinedReportSettings> = NonUndefinedReportSettings[Key]
// eslint-disable-next-line max-len
export type TileSettingsValue<Key extends keyof NonUndefinedReportSettings> = ReportSettingsValue<Key>[keyof ReportSettingsValue<Key>]

export interface UserReportsSettings {
  [key: string]: ReportSettings
}

interface IUseTileSettings<T extends keyof NonUndefinedReportSettings> {
  tileSettings: Signal<TileSettingsValue<T>>
  updateTileSettings(tileSettings: TileSettingsValue<T>): void
  deleteTileSetting: (settingKey: keyof TileSettingsValue<T>) => void
  deleteTileSettingKey: (setting: keyof TileSettingsValue<T>, key: KeyOfSetting<T>) => void
}

export interface UseTileSettingsParams<T extends keyof NonUndefinedReportSettings> {
  tileType: T
  tileId: string
  reportId?: string
}
export const useTileSettings = <TileType extends keyof NonUndefinedReportSettings>({
  tileType,
  tileId,
  reportId,
}: UseTileSettingsParams<TileType>): IUseTileSettings<TileType> => {
  const {
    userSettings, updateUserSettings,
  } = useUserSettingsContext()
  const { analyseId } = useSelector((state: RootState) => state.location)
  const tileSettings = useSignal<TileSettingsValue<TileType>>(
    {} as TileSettingsValue<TileType>,
  )
  const finalReportId = useMemo(() => {
    const finalId = reportId ?? analyseId
    if (!finalId) {
      throw new Error('useTileSettings requires passing reportId or analyseId in state.')
    }
    return finalId
  }, [reportId, analyseId])

  const updateTileSettings = useCallback((newTileSettings: TileSettingsValue<TileType>) => {
    updateUserSettings({
      reports: {
        ...userSettings.value.reports,
        [finalReportId]: {
          ...userSettings.value.reports?.[finalReportId],
          [tileType]: {
            ...userSettings.value.reports?.[finalReportId]?.[tileType],
            [tileId]: {
              ...userSettings.value.reports?.[finalReportId]?.[tileType]?.[tileId],
              ...newTileSettings,
            },
          },
        },
      },
    })
  }, [finalReportId, userSettings.value])

  /** Deletes entire tile setting. */
  const deleteTileSetting = useCallback((key: keyof TileSettingsValue<TileType>) => {
    const payload = {
      ...userSettings.value,
    }
    const tileData = payload.reports?.[finalReportId]?.[tileType]?.[tileId] as TileSettingsValue<TileType>
    if (!tileData) return
    delete tileData[key]
    updateUserSettings(payload)
  }, [userSettings.value])

  /** Deletes a certain key of tile setting. */
  const deleteTileSettingKey = useCallback((
    setting: keyof TileSettingsValue<TileType>,
    key: KeyOfSetting<TileType>,
  ) => {
    const payload = {
      ...userSettings.value,
    }
    const tileData = payload.reports?.[finalReportId]?.[tileType]?.[tileId] as TileSettingsValue<TileType>
    if (!tileData || !tileData[setting]) return
    delete tileData[setting][key]
    updateUserSettings(payload)
  }, [userSettings.value])

  useEffect(() => {
    tileSettings.value = userSettings.value.reports
      ?.[finalReportId]?.[tileType]?.[tileId] as TileSettingsValue<TileType> ?? {}
  }, [userSettings.value.reports, finalReportId])

  return {
    tileSettings,
    updateTileSettings,
    deleteTileSetting,
    deleteTileSettingKey,
  }
}
