import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PopupActions } from 'reactjs-popup/dist/types'
import { useSignal } from '@preact/signals-react'
import { filterByDate, filterByNumber, filterText, filterTextByArray, isNumber, parseNumber, validateDateField } from '@dataplace.ai/functions/utils'
import { ISortableTableHeaderProps, ITableFilter, ITableFilterObject } from '../../../@types'
import { DateFilter, IDateFilter, INumberFilter, IPeopleFilter, ITextFilter, ITileFilter, NumberFilter, PeopleFilter, TextFilter, TileFilter } from '../../../atoms'
import { ICategoryFilter, PoisCategoryFilter } from '../../../atoms/PoisCategoryFilter'
import { CheckboxFilter, IBrandFilter } from '../../../atoms/CheckboxFilter'
import VolumeCheckboxFilter from '../../../atoms/VolumeCheckboxFilter/VolumeCheckboxFilter'

const emptyTableFilter: ITableFilter = {}

export interface UseTableFilterReturnValues {
  allFieldsValid: boolean;
  activeFilters: number;
  filter: ITableFilter;
  filters: (JSX.Element | null)[];
  restorePrevState: () => void;
  handleCancelClick: () => void;
  clearAllFilters: () => void;
  applyFiltersAndClose: () => void;
  getActiveFilterValue: (filter: ITableFilterObject) => string;
  deleteActiveFilter: (name: string) => void;
  tempFilter: ITableFilter
  setTempFilter: React.Dispatch<React.SetStateAction<ITableFilter>> | ((val: ITableFilter) => void)
  filterData: () => void
}
export interface UseTableFilterProps {
  headers: ISortableTableHeaderProps[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setData(data: any[]): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  popupRef: React.RefObject<PopupActions>
}
export const useTableFilter = ({
  headers, setData, data: _data, popupRef,
}: UseTableFilterProps): UseTableFilterReturnValues => {
  const { t } = useTranslation()
  const [allFieldsValid, setAllFieldsValid] = useState(true)
  const [activeFilters, setActiveFilters] = useState(0)
  const [filter, setFilter] = useState(emptyTableFilter)
  const tempFilter = useSignal<ITableFilter>(emptyTableFilter)
  const [minDateRange, setMinDateRange] = useState<null | Date>(null)
  const [maxDateRange, setMaxDateRange] = useState<null | Date>(null)

  const setTempFilter = (filterValue: ITableFilter) => {
    tempFilter.value = filterValue
  }

  const data = useMemo(() => _data, [JSON.stringify(_data)])

  const closePopup = () => popupRef?.current?.close()

  const filters = headers.map(header => {
    if (header.filter?.enabled) {
      switch (header.type) {
        case 'date':
          return (
            <DateFilter
              key={header.name}
              clearable={header.filter.clearable}
              filter={tempFilter.value as IDateFilter}
              header={header}
              maxDate={maxDateRange}
              minDate={minDateRange}
              setFilterValue={setTempFilter}
            />
          )
        case 'text':
          return (
            <TextFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              filter={tempFilter.value as ITextFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'number':
          return (
            <NumberFilter
              key={header.name}
              clearable={header.filter.clearable}
              filter={tempFilter.value as INumberFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'person':
          return (
            <PeopleFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter.value as IPeopleFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'category':
          return (
            <PoisCategoryFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter.value as ICategoryFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'checkbox':
          return (
            <CheckboxFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter.value as IBrandFilter}
              header={header}
              name={header.name}
              setFilterValue={setTempFilter}
            />
          )
        case 'tile':
          return (
            <TileFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter.value as ITileFilter}
              header={header}
              setFilterValue={setTempFilter}
            />
          )
        case 'volume':
          return (
            <VolumeCheckboxFilter
              key={header.name}
              checkboxes={header.filter.checkboxes}
              clearable={header.filter.clearable}
              data={data}
              filter={tempFilter.value as ITileFilter}
              header={header}
              name={header.name}
              setFilterValue={setTempFilter}
            />
          )
        default:
          return null
      }
    }
    return null
  })

  useEffect(() => {
    filterData()
  }, [data, filter])

  useEffect(() => {
    setAllFieldsValid(validateFields())
    countActiveFilters()
  }, [tempFilter.value])

  const filteringFunctions = {
    date: filterByDate,
    text: filterText,
    number: filterByNumber,
  }

  const limitDates = () => {
    const dateRange = data.map(item => item.date).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    if (dateRange.length) {
      setMinDateRange(new Date(new Date(dateRange[0]).setHours(0, 0, 0, 0)))
      setMaxDateRange(new Date(new Date(dateRange[dateRange.length - 1]).setHours(0, 0, 0, 0)))
    }
  }

  useEffect(() => {
    limitDates()
  }, [])

  const validateFields = () => {
    const outcomes = []
    for (const field in tempFilter.value) {
      // eslint-disable-next-line no-continue
      if (!tempFilter.value?.[field]) continue
      const {
        type, rule,
      } = tempFilter.value?.[field]
      if (typeof rule !== 'string' && !Array.isArray(rule)) {
        switch (type) {
          case 'date':
            outcomes.push(validateDateField(rule.from, minDateRange, maxDateRange))
            outcomes.push(validateDateField(rule.to, minDateRange, maxDateRange))
            break
          case 'number':
            outcomes.push(isNumber(+(parseNumber(rule.from))))
            outcomes.push(isNumber(+(parseNumber(rule.to))))
            break
          default:
            //
        }
      }
    }
    return !outcomes.includes(false)
  }

  const filterData = () => {
    if (!Object.keys(filter).length && !Object.keys(tempFilter.value).length) {
      setData(data)
      return
    }
    if (!validateFields()) { return }
    const newData = [...data].filter(row => {
      for (const field in filter) {
        // eslint-disable-next-line no-continue
        if (!row[field]) continue
        const {
          type, rule,
        } = filter[field]
        const filteringFunction = filteringFunctions[type as keyof typeof filteringFunctions]
        if (type === 'person' && Array.isArray(rule)) {
          const nameIncludes = filterTextByArray(row[field].firstAndLastName, rule)
          const emailIncludes = filterTextByArray(row[field].email, rule)
          if (!nameIncludes && !emailIncludes) { return false }
        }
        else if (type === 'category' && Array.isArray(rule)) {
          const categoryIncludes = filterTextByArray(row[field], rule)
          if (!categoryIncludes) { return false }
        } else if (type === 'volume' && Array.isArray(rule)) {
          const volumeIncludes = rule.includes(row[field].toString())
          if (!volumeIncludes) return false
        } else if (Array.isArray(rule)) {
          if (!filterTextByArray(row[field], rule)) { return false }
        } else if (!Array.isArray(rule)) {
          if (!filteringFunction(row[field], rule)) { return false }
        }
      }
      return true
    })
    setData(newData)
    setFilter(tempFilter.value)
  }

  const applyFiltersAndClose = () => {
    countActiveFilters()
    setFilter(tempFilter.value)
    closePopup()
  }

  const restorePrevState = () => setTempFilter({
    ...filter,
  })

  const handleCancelClick = () => {
    restorePrevState()
    closePopup()
  }

  const clearAllFilters = () => setTempFilter(emptyTableFilter)
  const countActiveFilters = () => {
    setActiveFilters(Object.values(tempFilter.value).reduce((acc, curr) =>
      (Array.isArray(curr.rule) ? acc + (curr.rule.filter(category => category !== 'all').length) : acc + 1), 0))
  }

  const getActiveFilterValue = (filter: ITableFilterObject) => {
    if (typeof filter.rule === 'string') {
      return (filter.type === 'category'
        ? t(`placeme.pois_tile.category.${filter.rule.toLocaleLowerCase().split(/[_ -]+/)
          .join('-')}`)
        : filter.rule
      ) }
    if (Array.isArray(filter.rule)) {
      if (filter.type === 'category') { filter.rule.sort((a, b) => t(`placeme.pois_tile.category.${a.toLocaleLowerCase().split(/[_ -]+/)
        .join('-')}`).localeCompare(t(`placeme.pois_tile.category.${b.toLocaleLowerCase().split(/[_ -]+/)
        .join('-')}`))) } else {
        filter.rule.sort((a, b) => a.localeCompare(b))
      }

      if (filter.rule.length > 1) { return (filter.type === 'category'
        ? `${t(`placeme.pois_tile.category.${filter.rule[0].toLocaleLowerCase().split(/[_ -]+/)
          .join('-')}`)}, +${filter.rule.length - 1}`
        : `${filter.rule[0]}, +${filter.rule.length - 1}`) }
      return (filter.type === 'category'
        ? t(`placeme.pois_tile.category.${filter.rule[0].toLocaleLowerCase().split(/[_ -]+/)
          .join('-')}`)
        : filter.rule[0]
      )
    }
    if (!filter.rule.from) { return `${t('generic.range.to')} ${filter.rule.to}` }
    if (!filter.rule.to) { return `${t('generic.range.from')} ${filter.rule.from}` }
    if (filter.rule.from === filter.rule.to) { return filter.rule.from }
    return `${t('generic.range.from')} ${filter.rule.from} ${t('generic.range.to')} ${filter.rule.to}`
  }

  const deleteActiveFilter = (name: string) => {
    const newFilter = {
      ...filter,
    }
    delete newFilter[name]
    setFilter(newFilter)
    setTempFilter(newFilter)
  }

  return {
    allFieldsValid,
    activeFilters,
    filter,
    filters,
    restorePrevState,
    handleCancelClick,
    clearAllFilters,
    applyFiltersAndClose,
    getActiveFilterValue,
    deleteActiveFilter,
    filterData,
    tempFilter: tempFilter.value,
    setTempFilter,
  }
}
